import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './assets/styles/common.less'
import ui from './components/library'
import VueParticles from 'vue-particles'

// 插件的使用，在main.js使用app.use(插件)
createApp(App).use(VueParticles).use(router).use(store).use(ui).mount('#app')

import {tones} from "./assets/keyTone/index";
import { images } from './assets/images/dance';

/** 缓存钢琴音频和舞蹈图片，确保网络不好时也能正常使用 */
const CACHE_NAME="chache-v1";

const urlsToCache=[];

for(let key in tones){
    if(key=="c5"){
        urlsToCache.push(tones[key]);
        continue;
    }
    for(let url of tones[key]){
        urlsToCache.push(url);
    }
}

urlsToCache.push(...images);

if("serviceWorker" in navigator){
    window.addEventListener("load",()=>{
        navigator.serviceWorker.register("/service-worker.js").then(registration=>{
            console.log("Service Worker registered:",registration);
            caches.open(CACHE_NAME).then(cache=>{
                cache.addAll(urlsToCache);
            });
        }).catch(error=>{
            console.log("Service Worker Error:",error);
        });
    });
}